<template>
    <div>
        <!-- <b-card no-body class="card-statistics"> -->
            <!-- <b-card-header>
                <b-card-title>Input Details</b-card-title>
                <b-card-text class="font-small-2 mr-25 mb-0" v-if="input">
                    Updated &nbsp;
                </b-card-text>
            </b-card-header> -->
            <!-- <b-card-body class="statistics-body"> -->
                <b-row v-if="!saving" class="mt-2">
                    <b-col cols="12">
                        <b-row>
                            <b-col
                                cols="12"
                                md="4"
                                class="mb-2 mb-xl-0"
                                v-if="input"
                            >
                                <b-media no-body>
                                    <b-media-aside
                                        class="mr-2"
                                    >
                                        <b-avatar
                                            size="48"
                                            variant="light-info"
                                        >
                                            <feather-icon size="24" icon="KeyIcon" />
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">{{ input.name }}</h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            Unit: {{ input.units[0].name }} Abbreviation: {{ input.units[0].abbreviation }}
                                        </b-card-text>
                                        <b-card-text class="font-small-3 mb-0">
                                            Pricing: Per {{ input.pricing.description }}
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>

                            <b-col
                                cols="12"
                                md="4"
                                class="mb-2 mb-xl-0"
                                v-if="input"
                            >
                                <b-media no-body>
                                    <b-media-aside
                                        class="mr-2"
                                    >
                                        <b-avatar
                                            size="48"
                                            variant="light-success"
                                        >
                                            <feather-icon size="24" icon="CodesandboxIcon" />
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">Packaging</h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            <span v-if="input.packaging">{{ input.packaging.amount }} {{ input.packaging.unit }}</span>
                                            <span v-else-if="input.unit.needDescription">Not specified</span>
                                            <span v-else>Not Applicable</span>
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>

                            <b-col
                                cols="12"
                                md="4"
                                class="mb-2 mb-xl-0"
                                v-if="input"
                            >
                                <b-media no-body>
                                    <b-media-aside
                                        class="mr-2"
                                    >
                                        <b-avatar
                                            size="48"
                                            variant="light-danger"
                                        >
                                            <feather-icon size="24" icon="HeartIcon" />
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">Recommended/Hectare</h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            <span v-if="input.recommendation">{{ input.recommendation.amount }} {{ input.recommendation.unit.abbreviation }}</span>
                                            <span v-else></span>
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                        </b-row>
                        <hr />

                        <b-row class="mt-2">
                            <b-col cols="12" md="2">
                                <b-form-group label-for="season_id" label="Season">
                                    <validation-provider #default="{ errors }" name="season_id" rules="">
                                        <b-form-select
                                            id="season_id"
                                            name="season_id"
                                            size="sm"
                                            v-model="pricing.season_id"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="season in seasons"
                                                :key="season.id"
                                                :value="season.id"
                                            >
                                                {{ season.startYear }}/{{ season.endYear }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="2" v-if="input && input.pricing.pricing_type === 0">
                                <b-form-group label-for="price" label="Price">
                                    <validation-provider #default="{ errors }" name="price" rules="">
                                        <b-form-input
                                            id="price"
                                            name="price"
                                            size="sm"
                                            v-model="pricing.price"
                                            type="number"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.price">{{ serverErrors.price[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                            </b-col>
                            
                            <b-col
                                cols="12"
                                md="10"
                                v-if="input && input.pricing.pricing_type !== 0 && filters.length > 1 && filters[0].id !== input.pricing.pricing_id"
                            >
                                <b-row>
                                    <b-col cols="12" md="2" v-for="(filter, index) in filters" :key="filter.id">
                                        <label :label-for="filter.name">{{ filter.label }}</label>
                                        <b-form-select
                                            :id="filter.name"
                                            :name="filter.name"
                                            size="sm"
                                            v-model="filter.model"
                                            @change="changeFilters(filter.model, index)"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="location in filter.locations"
                                                :key="location.id"
                                                :value="location"
                                            >
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <b-row v-if="input && input.pricing.pricing_type != 0">
                            <b-col cols="12" md="12">
                                <b-table
                                    :items="location_prices"
                                    responsive
                                    :fields="columns"
                                    class="mb-0"
                                >

                                    <!-- category -->
                                    <template #cell(id)="data">
                                        <div class="d-flex align-items-center">
                                        <b-avatar
                                            class="mr-1"
                                            :variant="data.item.value !== null? 'light-success' : 'light-warning'"
                                        >
                                            <feather-icon
                                                size="18"
                                                :icon="data.item.value !== null? 'CheckCircleIcon' : 'AlertCircleIcon'"
                                            />
                                        </b-avatar>
                                        <span>{{ data.item.avatarTitle }}</span>
                                        </div>
                                    </template>

                                    <!-- revenue -->
                                    <template #cell(value)="data">
                                        <span v-if="data.item.value !== null" style="cursor: pointer;" class="text-success">
                                            <span v-if="!location_prices[data.index].editing" @click="editingPrice(data.index)" title="Edit price">
                                                <span style="border-bottom: 1px dotted;">{{ data.item.currency.abbreviation  }} {{ data.item.value.toLocaleString() }}</span> 
                                                <span><feather-icon size="12" icon="Edit3Icon" /></span>
                                            </span>
                                            <span v-else>
                                                <b-input-group size="sm" prepend="UGx">
                                                    <b-form-input
                                                        v-model="location_prices[data.index].newValue"
                                                        type="number"
                                                        style="text-align:right;"
                                                        :disabled="location_prices[data.index].saving"
                                                        @keyup.enter="savePrice(data.index)"
                                                    />
                                                    
                                                    <b-input-group-append is-text title="Cancel" @click="cancelEditPrice(data.index)" v-if="!location_prices[data.index].saving">
                                                        <feather-icon
                                                            icon="XIcon"
                                                            class="cursor-pointer"
                                                        />
                                                    </b-input-group-append>
                                                    <b-input-group-append is-text v-if="location_prices[data.index].saving">
                                                        <b-spinner
                                                            small
                                                            class="mr-1"
                                                            label="Small Spinner"
                                                            variant="primary"
                                                        />
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </span>
                                        </span>

                                        <span v-else style="cursor: pointer;" class="text-warning">
                                            <span v-if="!location_prices[data.index].editing" @click="editingPrice(data.index)" title="Set price">
                                                <span style="border-bottom: 1px dotted;">No prices</span> 
                                                <span><feather-icon size="12" icon="Edit3Icon" /></span>
                                            </span>
                                            <span v-else>
                                                <b-input-group size="sm" prepend="UGx">
                                                    <b-form-input
                                                        v-model="location_prices[data.index].newValue"
                                                        type="number"
                                                        style="text-align:right;"
                                                        :disabled="location_prices[data.index].saving"
                                                        @keyup.enter="savePrice(data.index)"
                                                    />
                                                    <b-input-group-append is-text title="Cancel" @click="cancelEditPrice(data.index)" v-if="!location_prices[data.index].saving">
                                                        <feather-icon
                                                            icon="XIcon"
                                                            class="cursor-pointer"
                                                        />
                                                    </b-input-group-append>
                                                    <b-input-group-append is-text v-if="location_prices[data.index].saving">
                                                        <b-spinner
                                                            small
                                                            class="mr-1"
                                                            label="Small Spinner"
                                                            variant="primary"
                                                        />
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </span>
                                        </span>
                                    </template>
                                </b-table>

                                <!-- <b-card
                                    class="card-transaction"
                                    no-body
                                >
                                    <b-card-header>
                                        <b-card-title>Prices</b-card-title>
                                    </b-card-header>

                                    <b-card-body>
                                    <div
                                        v-for="price in prices"
                                        :key="price.id"
                                        class="transaction-item"
                                    >
                                        <b-media no-body>
                                            <b-media-aside>
                                                <b-avatar
                                                rounded
                                                size="42"
                                                :variant="'light-warning'"
                                                >
                                                <feather-icon
                                                    size="18"
                                                    :icon="price.avatar"
                                                />
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body>
                                                <h6 class="transaction-title">
                                                    {{ price.name }}
                                                </h6>
                                            </b-media-body>
                                        </b-media>
                                        <div
                                            class="font-weight-bolder text-warning"
                                        >
                                            {{ price.value.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                            }) }}
                                        </div>
                                    </div>
                                    </b-card-body>
                                </b-card> -->
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- <b-col cols="12">
                        {{ input }}, {{ filters }}
                    </b-col> -->
                </b-row>

                <b-row v-else>
                    <b-col cols="12" class="text-center">
                        <b-spinner variant="primary" style="width: 3rem; height: 3rem;" />
                    </b-col>
                </b-row>
            <!-- </b-card-body> -->
        <!-- </b-card> -->
    </div>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,

    BMedia,
    BMediaAside,
    BAvatar,
    BImg,
    BMediaBody,

    BTable,
    BPagination,
    BForm,
    BFormGroup,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,
    BBadge
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { required } from '@validations'
  import store from '@/store'
  import { ref, onUnmounted, onMounted } from '@vue/composition-api'
  import inputsStoreModule from '@/views/cromis/inputs/inputsStoreModule'
  
  export default {
    props: {},
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BCardText,

      BMedia,
      BMediaAside,
      BAvatar,
      BMediaBody,
      BImg,

      BTable,
      BPagination,
      BForm,
      BFormGroup,
      BInputGroupAppend,
      BButton,
      BFormSelect,
      BFormSelectOption,
      BInputGroup,
      BFormInput,
      vSelect,
      BDropdown,
      BDropdownItem,
      BFormDatepicker,
      BFormCheckbox,
      BSpinner,
      BBadge,
      ValidationObserver,
      ValidationProvider,
    },
    directives: {},
    setup(props, context) {
        const { id } = context.root.$router.currentRoute.params
        const saving = ref(false);
        const serverErrors = ref(null);
  
        const userData = ref(JSON.parse(localStorage.getItem('userData')))
        const client = ref(userData.value.client)
        const levels = ref(userData.value.levels)
        const locations = ref(userData.value.locations)

        const filters = ref([])
        const filtered = ref(null)
        const children = ref([])

        const seasons = ref([])
        const location_prices = ref([])

        const input = ref(null)
        const prices = ref([])

        const pricing = ref({
            index: null,
            season_id: null,
            input_id: null,
            pricing_id: null,
            pricing_type: null,
            modal_id: null,
            value: null,
            currency_id: null,
        })

        const columns = ref([
            { key: 'id', label: '', sortable: false, thStyle: { width: "3%" } },
            { key: 'name', label: 'Location', sortable: true },
            { key: 'value', label: 'Price', sortable: false, thStyle: { width: "18%" }, thClass: 'text-right', tdClass: 'text-right',},
        ])
  
        const CROMIS_STORE_MODULE_NAME = "cromis-input";
  
      // Register module
      if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) {
        store.registerModule(CROMIS_STORE_MODULE_NAME, inputsStoreModule);
      }
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(CROMIS_STORE_MODULE_NAME)) {
          store.unregisterModule(CROMIS_STORE_MODULE_NAME);
        }
      });
  
      onMounted(async () => {
        await store.dispatch('cromis-input/seasons')
                    .then(response => {
                        seasons.value = response.data.seasons

                        seasons.value.forEach(season => {
                            if(season.status && season.isRunning){
                                pricing.value.season_id = season.id
                            }
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })

        await store.dispatch('cromis-input/show', { id: id })
                    .then(response => {
                        input.value = response.data
                        pricing.value.input_id = input.value.id
                    })
                    .catch(error => {
                        console.log(error)
                    })

        if(input.value !== null && input.value.pricing.pricing_type !== 0){
            await store.dispatch('cromis-input/prices', { input_id: input.value.id, pricing_id: input.value.pricing.pricing_id })
                        .then(response => {
                            let currency = null

                            location_prices.value = response.data.input_prices.map((e) => {
                                if(currency === null && e.prices.length > 0){
                                    currency = e.prices[e.prices.length - 1].currency
                                }

                                return {
                                    id: e.id,
                                    name: e.name,
                                    value: e.prices.length > 0 ? e.prices[e.prices.length - 1].value : null,
                                    newValue: null,
                                    currency: e.prices.length > 0 ? e.prices[e.prices.length - 1].currency : currency,
                                    editing: false,
                                    saving: false,
                                }
                            })
                        })
                        .catch(error => {
                            console.log(error)
                        })
        }

        if(levels.value.length > 0){
            levels.value = levels.value[0]
    
            filters.value.push({
                id: levels.value.id,
                level: levels.value.name.toLowerCase(),
                model: null,
                label: levels.value.name,
                child: levels.value.children.length > 0? levels.value.children[0].name.toLowerCase() : null,
                locations: [], 
            })
    
            let list = levels.value.children
    
            while(list.length > 0){
                children.value.push(list[0])
    
                filters.value.push({
                    id: list[0].id,
                    level: list[0].name.toLowerCase(),
                    model: null,
                    label: list[0].name,
                    child: list[0].children.length > 0? list[0].children[0].name.toLowerCase() : null,
                    locations: [],
                })
    
                list = list[0].children
            }

            if(filters.value.length > 0 && locations.value.length > 0){
                setFiltersLocations(locations.value)
            }
        }
      });

    const setFiltersLocations = (locations) => {
        for(let i = 0; i < locations.length; i++){
            for(let x = 0; x < filters.value.length; x++){
                if(locations[i].level.name.toUpperCase() == filters.value[x].level.toUpperCase()){
                    filters.value[x].locations.push(locations[i])
                }
            }
        }

        // set list of prices for the first level
        if(filters.value.length > 0 && input.value != null && input.value.pricing.pricing_type != 0 && filters.value[0].id == input.value.pricing.pricing_id){
            prices.value = filters.value[0].locations.map(location => {
                return {
                    id: location.id,
                    name: location.name,
                    value: 0,
                    variant: 'light-success',
                    avatar: 'DollarSignIcon',
                }
            })
        }
    }

    const changeFilters = async (filteredLocation, index) => {
        if(!filteredLocation){
            for(let i = index + 1; i < filters.value.length; i++){
                filters.value[i].locations.splice(0)
                filters.value[i].location = null
            }

            if(index > 0){
                filtered.value = filters.value[index - 1].location
            }
            else{
                filtered.value = null
            }
        }
        else{
            if(filters.value[index + 1]){
                filters.value[index + 1].locations.splice(0)
            }
            filteredLocation.children.map((child) => {
                filters.value[index + 1].locations.push(child)
            })

            if(filteredLocation.children.length > 0){
                filters.value[index + 1].location = null
            }

            filtered.value = filters.value[index].location
        }
    }

    const editingPrice = (index) => {
        location_prices.value[index].editing = !location_prices.value[index].editing
    }

    const cancelEditPrice = (index) => {
        location_prices.value[index].editing = !location_prices.value[index].editing
        
        const pricing = ref({
            index: null,
            season_id: null,
            input_id: input.value.id,
            pricing_id: null,
            pricing_type: null,
            modal_id: null,
            value: null,
            currency_id: null,
        })
    }

    const savePrice = async (index) => {
        if(location_prices.value[index].newValue == null || location_prices.value[index].newValue == location_prices.value[index].value){
            location_prices.value[index].editing = !location_prices.value[index].editing
        }
        else{
            location_prices.value[index].saving = true

            pricing.value.index = index
            pricing.value.input_id = input.value.id
            pricing.value.pricing_id = input.value.pricing.pricing_id
            pricing.value.pricing_type = input.value.pricing.pricing_type
            pricing.value.modal_id = location_prices.value[index].id
            pricing.value.value = location_prices.value[index].newValue
            pricing.value.currency_id = location_prices.value[index].currency.id

            await store.dispatch('cromis-input/savePrice', pricing.value)
                    .then((response) => {
                        let currency = null

                        location_prices.value = response.data.input_prices.map((e) => {
                            if(currency === null && e.prices.length > 0){
                                currency = e.prices[e.prices.length - 1].currency
                            }

                            return {
                                id: e.id,
                                name: e.name,
                                value: e.prices.length > 0 ? e.prices[e.prices.length - 1].value : null,
                                newValue: null,
                                currency: e.prices.length > 0 ? e.prices[e.prices.length - 1].currency : currency,
                                editing: false,
                                saving: false,
                            }
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
        }
    }
  
    const handleUpdate = async (item) => {
        saving.value = true;
        const valid = await isFormValid();

        if (!valid) {
            saving.value = false;
            return;
        }

        await store
            .dispatch("cromis-input/update", { id: item.id, data: item })
            .then((response) => {
            refetch();
            saving.value = false;

            myModal.value.hide();

            context.root.$swal({
                icon: "success",
                text: `Changes to input # ${response.data.name} has been saved successfully!`,
                showConfirmButton: true,
                timer: 3000,
                customClass: {
                confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
            });
            })
            .catch((error) => {
                saving.value = false;
                if (error.response.status === 422) {
                    serverErrors.value = error.response.data.errors;
                } else {
                    context.root.$swal({
                    icon: "error",
                    title: "Server Error",
                    text: "Something went wrong. See tech support",
                    showConfirmButton: true,
                    customClass: {
                        confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                    });
                }
        });
    }
  
      return {
        // Data
        client,
        filters,
        filtered,
        levels,
        locations,
        seasons,
        location_prices,

        input,
        pricing,
        columns,
  
        // Methods
        setFiltersLocations,
        changeFilters,
        required,
        saving,
        serverErrors,
        handleUpdate,
        editingPrice,
        cancelEditPrice,
        savePrice,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  